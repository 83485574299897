import React from "react";
import { EditUser } from "../../../components/EditUser";

interface Props {
    uid: string,
};

const UserPage = ({ uid }: Props) => {
    return (
        <>
            <EditUser Uid={uid} Organization={false} />
        </>
    )
};

export default UserPage;